// import * as npm from "../../../package.json";


export const environment = {
  production: false,
  env: "dev",
  // version: npm.version,
  baseUrl: 'https://topsalon-dev.codefab.it',
  authUrl: 'https://topsalon-auth-dev.codefab.it',
  apiUrl: 'https://topsalon-api-dev.codefab.it',
  mediaUrl: 'https://topsalon-media-dev.codefab.it'
};

